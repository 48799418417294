export class UserClass {
    id: number
    first_name: string
    last_name: string
    email: string
    role: any


    constructor(){
        this.id = 0;
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.role = null

    }
}